.services { 
    width: 100%;
    padding: 76px 20px 68px;
    margin-bottom: 55px;
    @media(max-width: 1440px) {
        padding: 60px 20px;
    }
	.services__container { 
		.services__title { 
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 44px;
            @media(max-width: 1440px) {
                margin-bottom: 20px;
            }
            .title-span {
                font-size: 17px;
                font-weight: 600;
                display: flex;
                align-items: center;
                column-gap: 8px;
            }
			h2 { 
                color: #1C2752;
                text-align: center;
                font-family: Rubik;
                font-size: 37px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @media(max-width: 1440px) {
                    font-size: 32px;
                }
			}
		}

		.services__grid { 
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            @media(max-width: 768px) {
                grid-gap: 16px;
            }
            @media(max-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }
            @media(max-width: 768px) {
                grid-template-columns: 1fr;
            }
			.services__item { 
                width: 100%;
                padding: 48px 36px;
                border-radius: 2px;
                background: #1C2752;
                @media(max-width: 1440px) {
                    padding: 30px 16px;
                }
                a{
                    &:hover{
                            opacity: .6;
                        }
                }
				.num { 
                    color: #FFB342;
                    font-family: Roboto;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    display: block;
                    margin-bottom: 20px;
                    @media(max-width: 1440px) {
                        font-size: 36px;
                        margin-bottom: 16px;
                    }
				}

				h4 { 
                    margin-bottom: 12px;
					a { 
                        color: #FFB342;
                        font-family: Roboto;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        @media(max-width: 1440px) {
                            font-size: 18px;
                        }
                        
					}
				}

				p { 
                    color: #D2D2D2;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 30px;
                    @media(max-width: 1440px) {
                        font-size: 16px;
                    }
				}

				.more { 
                    color: #FFF;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration-line: underline;
                    @media(max-width: 1440px) {
                        font-size: 16px;
                    }
				}
			}
		}
	}
}