.secondPartHome{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    justify-content: center;
    align-items: center;
    margin: 0px 0 115px;
}

.home-grid{
    display: flex;
    row-gap: 25px;
    column-gap: 25px;
    justify-content: center;
    flex-wrap: wrap;
}

.home-grid > img{
    width: 440px;
    height: 410px;
    @media (max-width: 1000px) {
    width: 350px;
    height: 300px;
  }
  @media (max-width: 768px) {
    width: 270px;
    height: 220px;
  }
}