.advantages {
  width: 100%;
  padding: 94px 20px;
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 44px 0;
  }
  .aboutImg{
    width: 48.59%;
    object-fit: cover;
    height: 100%;
    @media (max-width: 1440px) {
      width: 43%;
    }
    @media (max-width: 1024px) {
      width: 80%;
      margin-top: 25px;
    }
  }

  .advantages__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 110px;
    @media (max-width: 1440px) {
      gap: 40px;
    }
    @media (max-width: 1024px) {
      gap: 20px;
      flex-direction: column-reverse;
    }
    .advantages__left {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 34px;
      padding: 60px 20px;
      border-radius: 2px;
      background: rgba(28, 39, 82, 0.7);
      backdrop-filter: blur(12.5px);
      flex-direction: column;
      max-width: 630px;
      @media (max-width: 1440px) {
        max-width: 540px;
      }
      @media (max-width: 1024px) {
        max-width: unset;
      }
      @media (max-width: 520px) {
        padding: 20px;
      }
      p {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #fff;
        color: #fff;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-right: 36px;
        @media (max-width: 520px) {
          padding: 10px;
          flex-direction: column;
          gap: 16px;
          text-align: center;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 142px;
          min-width: 142px;
          height: 64px;
          background: #ffb342;
          color: #1c2752;
          text-align: center;
          font-family: Mulish;
          font-size: 25px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          margin-right: 36px;
          @media (max-width: 520px) {
            max-width: unset;
            min-width: unset;
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }

    .advantages__right {
      width: 100%;
      max-width: 750px;
      @media (max-width: 1440px) {
        max-width: 600px;
      }
      @media (max-width: 1024px) {
        max-width: unset;
        padding: 0 20px;
      }
      .title-span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        padding: 8px 16px;
        gap: 10px;
        border-radius: 2px;
        background: #f4f4f4;
        color: #1c2752;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: max-content;
        @media (max-width: 520px) {
          padding: 8px;
          font-size: 14px;
          svg {
            width: 20px;
            height: auto;
          }
        }
      }

      h2 {
        color: #1c2752;
        font-family: Rubik;
        font-size: 45px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 60px;
        @media (max-width: 1440px) {
          font-size: 36px;
          margin-bottom: 20px;
        }
        @media (max-width: 1024px) {
          font-size: 30px;
        }
        @media (max-width: 520px) {
          font-size: 24px;
        }
      }

      .advantages__item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 44px;
        &:last-child {
          margin-bottom: 0;
        }
        @media (max-width: 520px) {
          margin-bottom: 20px;
          .icon {
            svg {
              width: 24px;
              height: auto;
            }
          }
        }
        .text {
          width: 100%;
          max-width: 514px;
          p {
            color: #1c2752;
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 10px;
            @media (max-width: 520px) {
              font-size: 16px;
            }
          }

          span {
            color: #7e7e7e;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            @media (max-width: 520px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
