.NavigationMenu {
  background-color: lightgray;
  padding: 10px 0;
  box-shadow: 0px 5px 5px grey;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.NavMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.LogoAndLinks {
  display: flex;
  align-items: center;
  column-gap: 100px;
  @media (max-width: 1199px) {
    width: 100%;
    justify-content: space-between;
  }
}

.hamburger {
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
}
.LogoAndLinks > a > img {
  width: 100px;
  cursor: pointer;
}

.NavLinks {
  display: flex;
  column-gap: 60px;
  @media (max-width: 768px) {
    display: none;
  }
}

/* .NavLinks > .active {
  display: inline-block;
  border-bottom: 2px solid black;
  padding-bottom: 2px;
} */

.NavLinks a {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid black;
  }
}
.NavConnect {
  display: flex;
  align-items: center;
  column-gap: 80px;
  
}
.mailDiv {
  display: flex;
  align-items: center;
}
.mailDiv > img {
  width: 60px;
}

.mailDiv a {
  font-size: 15px;
  opacity: 0.7;
  color: black;
}
.numberBox {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.numberBox > img {
  width: 35px;
}

.numberDiv {
  display: flex;
  flex-direction: column;
}

.numberDiv a{
  font-size: 15px;
  opacity: 0.7;
  color: black;
}

@media (max-width: 1199px) {
    .NavConnect {
      display: none;
    }
}