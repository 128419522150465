.footer {
  background-color: lightgray;
  color: red;
  padding: 20px 0;
  box-shadow: 0px -5px 5px gray;
  padding-top: 25px;
  @media (max-width: 1440px) {
    padding-left: 20px;
    padding-right: 20px;
  }

}
.items {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  column-gap: 20px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 17px;
  }
}
.firstPart {
  display: flex;
  flex-direction: column;
}
.pagesItems {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.pagesItems a {
  letter-spacing: 1.2px;
  font-size: 17px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid black;
  }
}

.footerText {
  display: flex;
  column-gap: 55px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    row-gap: 20px;
    margin: 15px 0;
  }
}
.secondPart {
  display: flex;
  align-items: center;
  column-gap: 34px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.secondPart1 {
  display: none;
  align-items: center;
  column-gap: 34px;
  @media (max-width: 768px) {
    display: flex;
  }
}

.secondPart img {
  filter: grayscale(1);
  &:hover {
    filter: grayscale(0);
  }
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 17px;
  }
}

.footerBottom p {
  color: black;
  font-size: 17px;
  font-weight: 600;
}
.backInfo {
  display: flex;
  flex-direction: column;
}
.backInfo a {
  font-size: 15px;
  opacity: 0.7;
  color: black;
}
