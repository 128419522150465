.home-slider {
  margin: 80px 0;
}

.slider {
  display: flex;
  align-items: center;
  column-gap: 38px;
  @media (max-width:1226px) {
    flex-direction: column-reverse;
    row-gap: 38px;
  }
}
.leftBlock {
  background-color: rgb(228, 228, 228);
  border: 1px solid grey;
  box-shadow: 5px 5px 5px grey;
  z-index: 10;
}
.leftBlock ul {
  height: 380px;
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.leftBlock ul li a {
  font-weight: 600;
  border-bottom: 2px solid transparent;
  padding-bottom: 2px;
  color: black;
  margin-left: 10px;
  &:hover {
    border-bottom: 2px solid black;
  }
}

.rightBlock > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.slider-container {
  width: 74%;
}

.first{
    height: 380px;
    @media (max-width:700px) {
      height: auto;
    }
}

h3{
    margin-top: 160px;
    text-align: center;
}

.imgSlider{
  width: 100%;
  object-fit: cover;
  height: 100%;
}