.modalNavMenu {
  color: white;
  flex-direction: column;
  left: 0;
  top: 90px;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  display: flex;
  row-gap: 55px;
  background-color: rgb(12, 2, 12, 0.9);
  position: fixed;
  width: 100%;
  height: 100dvh;
}

.modalNavMenu a{
    font-size: 17px;
    border-bottom: 1px solid rgb(255, 254, 254);
    padding-bottom: 2px;
}

