* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}
.container {
    max-width: 1440px;
    margin: auto;
}

html{
    scroll-behavior: smooth;
}

