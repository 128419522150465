.aboutTitle {
  display: flex;
  justify-content: space-between;
  column-gap: 55px;
  padding: 67px 25px;
  @media (max-width: 1171px) {
    flex-direction: column;
    align-items: center;
    row-gap: 55px;
  }
}

.aboutTitle img {
  width: 48.59%;
    object-fit: cover;
    height: 100%;
    @media (max-width: 1440px) {
      width: 43%;
    }
    @media (max-width: 1024px) {
      width: 80%;
      margin-top: 25px;
    }
}

.aboutInfo {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.aboutInfo > p {
  font-size: 17px;
  max-width: 700px;
  line-height: 1.7;
  font-weight: 400;
  word-spacing: 7px;
}
